import React, { Component } from 'react';
import { Link } from 'gatsby';
import Header from '../components/header11'
import Footer from '../components/footer4'
import {projectContent1,homeSliderContent4} from '../components/SliderContent'
import ClientSlider1 from '../components/clientSlider1';
import ProjectSlider1 from '../components/projectSlider1';
import HomeSlider from '../components/homeslider1'
// import ScrollToTop from '../components/scrollToTop';
import Img from "gatsby-image";
import { graphql } from "gatsby"
import tawkTo from "tawkto-react";
import LazyLoad from 'react-lazyload';

const tawkToPropertyId = '602f41189c4f165d47c4d4fa'
const tawkToKey = '1ev6fntet'


// import { GatsbyImage as Img } from '@wardpeet/gatsby-image-nextgen/compat';

const bg = require('../images/background/map-bg.png')

class Index4 extends Component {
    componentDidMount(){
        tawkTo(tawkToPropertyId, tawkToKey)
    }

    render() {
        return (
            <>  
                <Header/>
                <LazyLoad>

                <HomeSlider 
                data={homeSliderContent4}
				contentWrapperCls="sliderStyle4"
                btnCls="site-button"
                btnText="CONTACT US"
                btn2Text="OUR PRODUCTS"
                show2Btn
                />
                </LazyLoad>
                <div class="page-content bg-white">
                    <div class="content-block">
                    
                        <div class="section-full">
                            <div class="row spno about-industry">
                                <div class="col-lg-7 wow fadeIn" data-wow-duration="2s" data-wow-delay="0.2s">
                                    <div class="dlab-post-media"> 
                                            <Img fluid={this.props.data.truck.childImageSharp.fluid} alt="" className="img-cover"/>
                                    </div>
                                </div>

                                <div class="col-lg-5 bg-white wow fadeIn" data-wow-duration="2s" data-wow-delay="0.4s">
                                    <div class="service-box style2">
                                        <div>
                                            <h2 class="title text-black"><span>Speedy</span> <br/>Accurate<br/><span> Professional</span> </h2>
                                            <p>Established in 2004, we always strive to provide our customers with the highest quality services following our slogan: "Speedy – Accuracy – Professional."</p>
                                            <Link to="/about" class="site-button outline outline-2 btnhover11">ABOUT US</Link>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 wow fadeIn">
                                    <div class="dlab-post-media"> 
                                        <LazyLoad>
                                            <Img fluid={this.props.data.paper.childImageSharp.fluid} alt="" className="img-cover"/>
                                        </LazyLoad>
                                        {/* <img src={require("../images/duynhat/factory/DSC_3879.jpg")} alt="" class="img-cover"/> */}
                                    </div>
                                </div>
                                <div class="col-lg-4 bg-primary text-white" >
                                    <div class="service-box style2">
                                        <div>
                                            <h2 class="title text-black">Paper<br/><span> Packaging</span> </h2>
                                            <p>We offer various kinds of printing to cater to different usages such as flexographic, offset press, and roto-gravure.</p>
                                            <Link to="/paper" class="site-button outline white outline-2 btnhover11">View Paper Packaging Products</Link>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 wow fadeIn" >
                                    <div class="dlab-post-media"> 
                                    <LazyLoad>
                                        <Img fluid={this.props.data.flexible.childImageSharp.fluid} alt="" className="img-cover"/>
                                    </LazyLoad>
                                        {/* <img src={require("../images/duynhat/factory/DSC_36020.jpg")} alt="" class="img-cover"/> */}
                                    </div>
                                </div>
                                <div class="col-lg-4 bg-primary text-white" >
                                    <div class="service-box style2">
                                        <div>
                                            <h2 class="title text-black">Flexible<br/><span> Packaging</span> </h2>
                                            <p>Our plastic packaging solution provides high rigidity with resistance against moisture, chemical or to protect scent and flavor of the products inside.</p>
                                            <Link to="/flexible" class="site-button outline white outline-2 btnhover11">View Flexible Packaging Products</Link>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 wow fadeIn">
                                    <div class="dlab-post-media">
                                        <LazyLoad>
                                            <Img fluid={this.props.data.label.childImageSharp.fluid} alt="" className="img-cover"/>
                                        </LazyLoad>
                                        {/* <img src={require("../images/duynhat/factory/DSC_9222.jpg")} alt="" class="img-cover"/> */}
                                    </div>
                                </div>
                                <div class="col-lg-4 bg-primary text-white" data-wow-duration="2s" data-wow-delay="0.8s">
                                    <div class="service-box style2">
                                        <div>
                                            <h2 class="title text-black"><span>Printed</span> Labels &amp; Stickers<br/> </h2>
                                            <p>Our label and sticker solutions are offered in various size and designs to cater to your specific needs.</p>
                                            <Link to="/labels" class="site-button outline white outline-2 btnhover11">View Labels &amp; Stickers</Link>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>

                        
                        <div class="section-full content-inner-2 bg-img-fix overlay-black-dark wow fadeIn" data-wow-duration="2s" data-wow-delay="0.8s" style={{ backgroundImage: "url(" + bg + ")" }}>
                            <div class="container">
                                <div class="section-head text-white text-center">
                                    <h2 class="title text-white"><Link to="/products" style={{color:"white"}}>Our Products</Link> </h2>
                                    <p>Our packaging solutions include various kinds of soft, plastic packaging, paper boxes, labels, bags, as well as various kinds of laminated bags & rolls for auto packing.</p>
                                    <Link to="/products" class="site-button outline white outline-2 btnhover11">View Our Products</Link>
                                </div>
                                <div class="row icon-white">
                                    <div class="col-lg-12 col-md-12">
                                        <LazyLoad>
                                            <ProjectSlider1 data={projectContent1}/>
                                        </LazyLoad>
                                    </div>
                                </div>
                            </div>
                        </div>

                       
                                <div class="section-full content-inner-2 wow fadeIn" data-wow-duration="2s" data-wow-delay="0.6s" style={{paddingBottom:0}}>
                                    <div class="container">		
                                        <div class="section-head text-black text-center">
                                            <h2 class="title text-capitalize">Our Customers</h2>
                                            <p>We service many large seafood exporters, FMCG companies, local and international manufacturers. Many packaging 
                                                products by Duy Nhat Pack are found in major global retailers including 
                                                Coles, Woolworths, Aldi, Costco, and independents, P&amp;C and Food Service.
                                            </p>
                                            {/* <h2 class="title text-capitalize">We work with our customers to provide the highest quality products,<br/><span class="text-primary">Join with our satisfied customers.</span></h2> */}
                                        </div>
                                        <LazyLoad>
                                            <ClientSlider1 hideBorder="border" />
                                        </LazyLoad>
                                    </div>
                                </div>
                                <div class="container">		
                                        <div class="section-head text-black text-center">
                                            <h2 class="title text-capitalize">Our Certificates</h2>
                                            <p> Quality control to minimize risks & errors prior delivering to customers to products is our highest priority. Therefore, 
                                                we have been continuously implementing modern quality assurance programs such as ISO 22000:2018, ISO 9001:2005 & BRC for our packaging 
                                            </p>
                                        </div>
                                        </div>
                                <div class="row justify-content-center">
                                    <div class="col-lg-6 col-md-6 col-sm-6 m-b30">
                                        <LazyLoad>
                                            <Img fluid={this.props.data.cert.childImageSharp.fluid}  alt="" className="img-cover"/>
                                        </LazyLoad>
                                    </div>
                                </div>
                                
                        <div class="section-full bg-gray wow">
                            <div class="row spno about-industry">
                        <div class="col-lg-4 bg-gray " data-wow-duration="2s" data-wow-delay="0.2s">
                                    <div class="service-box style2">
                                        <div>
                                            <h2 class="title text-black"><span>Our  </span> <br/>Facility</h2>
                                            <p>Duy Nhat Pack operates at newly opened 80,000m<sup>2</sup> facility located 
                                        in An Nghiep Industrial Park, Soc Trang...</p>
                                            <Link to="/facility" class="site-button outline outline-2 btnhover11">View Our Facility</Link>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-8 wow fadeIn" data-wow-duration="2s" data-wow-delay="0.4s">
                                    <div class="dlab-post-media"> 
                                        <LazyLoad>
                                        {/* <img src={require("../images/duynhat/company/DJI_0150-copy.jpg")} alt="" class="img-cover"/> */}
                                            <Img fluid={this.props.data.factory.childImageSharp.fluid} alt="" className="img-cover"/>
                                        </LazyLoad>
                                    </div>
                        </div>
                        </div>
                        </div>
                        {/* <div class="section-full content-inner-2 bg-gray wow fadeIn" data-wow-duration="2s" data-wow-delay="0.3s">
                            <div class="container">
                                <div class="section-head text-black text-center">
                                    <h2 class="title">Testimonials</h2>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry has been the industry's standard dummy text ever since the been when an unknown printer.</p>
                                </div>	
                                <Testimonial2/>
                            </div>
                        </div> */}
                        
                        <div class="section-full content-inner bg-white">
                            <div class="container">
                                <div class="section-head text-black text-center">
                                    <h2 class="title text-capitalize">latest blog</h2>
                                    {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry has been the industry's standard dummy text ever since the been when an unknown printer.</p> */}
                                </div>
                                
                                <div class="row">
                                
                                    {this.props.data.page.edges.map((page,idx) =>{
                                        if(idx >=0 && idx <=2){
                                            return(
                                                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s">
                                                    <div class="blog-post blog-grid blog-rounded blog-effect1 fly-box">
                                                        <div class="dlab-post-media dlab-img-effect"> 
                                                            <a  href={page.node.frontmatter.slug} aria-label="blog">
                                                            <LazyLoad>
                                                                <Img fluid={page.node.frontmatter.featuredImage.childImageSharp.fluid} alt="DN Pack Employee" className="img-cover"/>
                                                            </LazyLoad>
                                                            </a>
                                                        </div>
                                                        <div class="dlab-info p-a20 border-1 bg-white">
                                                            <div class="dlab-post-meta">
                                                                <ul>
                                                                    <li class="post-date"> <span>{page.node.frontmatter.date}</span> </li>
                                                                    <li class="post-author"> By <a href={page.node.frontmatter.slug}>{page.node.frontmatter.author}</a> </li>
                                                                </ul>
                                                            </div>
                                                            <div class="dlab-post-title">
                                                            <h3 class="post-title"><a href={page.node.frontmatter.slug}>{page.node.frontmatter.title}</a></h3>
                                                            </div>
                                                            <div class="dlab-post-text">
                                                            <p style={{color:"#343a40"}}>{page.node.frontmatter.summary}</p>
                                                            </div>
                                                            <div class="dlab-post-readmore"> 
                                                            <a href={page.node.frontmatter.slug} title="READ MORE" rel="bookmark" class="site-button">Read The Article
                                                                    <i class="ti-arrow-right"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}})}
                                   
                                </div>
                            </div>
                        </div>
                        
                        {/* <div class="section-full content-inner-2 bg-gray wow fadeIn" data-wow-duration="2s" data-wow-delay="0.6s">
                            <div class="container">		
                                <div class="section-head text-black text-center">
                                    <h2 class="title text-capitalize">We work with our partners to provide project perfection,<br/><span class="text-primary">join with our parnership.</span></h2>
                                </div>
                                <ClientSlider1/>
                            </div>
                        </div> */}
                    </div>
                </div>                
                <Footer/>       
                {/* <ScrollToTop className="style1 white icon-up"/>       */}
            </>
        )
    }
}
export default Index4;
export const query = graphql`
query MyQuery {
    page: allMarkdownRemark(
		sort: { order: DESC, fields: [frontmatter___date] }
	)  {
      edges{
		  node{
			frontmatter {
				date(formatString: "MMMM DD, YYYY")
				slug
				title
				author
				tags
				urls
				summary
				featuredImage {
					childImageSharp {
					  fluid(quality: 100) {
						...GatsbyImageSharpFluid
					  }
					}
				  }
				}
				
		  }
	  }
      
    }
    truck:file(relativePath: { eq: "duynhat/factory/DSC_7915.jpg" }){
      childImageSharp {
        fluid(jpegQuality: 100){
            ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    factory:file(relativePath: { eq: "duynhat/company/DJI_0150-copy.jpg" }){
        childImageSharp {
          fluid(jpegQuality: 100){
              ...GatsbyImageSharpFluid_tracedSVG
          }
        }
    }
    flexible:file(relativePath: { eq: "duynhat/factory/DSC_36020.jpg" }){
        childImageSharp {
          fluid(quality: 100,maxWidth:550){
              ...GatsbyImageSharpFluid_withWebp
          }
        }
    }
    paper:file(relativePath: { eq: "duynhat/factory/DSC_3879.jpg" }){
        childImageSharp {
          fluid(quality: 100,maxWidth:550){
              ...GatsbyImageSharpFluid_withWebp
          }
        }
    }
    label:file(relativePath: { eq: "duynhat/factory/DSC_9222.jpg" }){
        childImageSharp {
          fluid(quality: 100,maxWidth:550){
              ...GatsbyImageSharpFluid_withWebp
          }
        }
    }
    cert: file(relativePath: { eq: "duynhat/cert.png" }) {
        childImageSharp  {
            fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
        }
        }
  }
`
