// export const blogContent1 = [
//     {
//         image: require("../imagesblog/grid/pic1.jpg"),
//         title:"Seven Doubts You Should",
//         description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
//         author:"Jack",
//     },
//     {
//         image: require("../imagesblog/grid/pic2.jpg"),
//         title:"Seven Doubts You Should",
//         description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
//         author:"Jack",
//     },
//     {
//         image: require("../imagesblog/grid/pic3.jpg"),
//         title:"Seven Doubts You Should",
//         description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
//         author:"Jack",
//     },
//     {
//         image: require("../imagesblog/grid/pic1.jpg"),
//         title:"Seven Doubts You Should",
//         description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
//         author:"Jack",
//     },
//     {
//         image: require("../imagesblog/grid/pic2.jpg"),
//         title:"Seven Doubts You Should",
//         description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
//         author:"Jack",
//     },
//     {
//         image: require("../imagesblog/grid/pic3.jpg"),
//         title:"Seven Doubts You Should",
//         description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
//         author:"Jack",
//     },
// ]

// export const blogContent2 = [
//     {
//         image: require("../imagesour-services/ship/pic1.jpg"),
//         title:"Seven Doubts You Should",
//         author:"Jack",
//     },
//     {
//         image: require("../imagesour-services/ship/pic1.jpg"),
//         title:"Seven Doubts You Should",
//         author:"Jack",
//     },
//     {
//         image: require("../imagesour-services/ship/pic2.jpg"),
//         title:"Seven Doubts You Should",
//         author:"Jack",
//     },
//     {
//         image: require("../imagesour-services/ship/pic3.jpg"),
//         title:"Seven Doubts You Should",
//         author:"Jack",
//     },
//     {
//         image: require("../imagesour-services/ship/pic2.jpg"),
//         title:"Seven Doubts You Should",
//         author:"Jack",
//     },
// ]


// export const blogContent3 = [
//     {
//         image: require("../images/duynhat/about/NewsImage_10-07-2019-16-03-06.jpg"),
//         title:"Employees",
//         description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
//         author:"Jack",
//     },
//     {
//         image: require("../images/duynhat/factory/DSC_7915.jpg"),
//         title:"Truck",
//         description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
//         author:"Jack",
//     },
//     {
//         image: require("../images/duynhat/factory/DSC_7895.jpg"),
//         title:"New Machine",
//         description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
//         author:"Jack",
//     },
//     {
//         image: require("../imagesblog/grid/pic1.jpg"),
//         title:"Seven Doubts You Should",
//         description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
//         author:"Jack",
//     },
//     {
//         image: require("../imagesblog/grid/pic2.jpg"),
//         title:"Seven Doubts You Should",
//         description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
//         author:"Jack",
//     },
//     {
//         image: require("../imagesblog/grid/pic3.jpg"),
//         title:"Seven Doubts You Should",
//         description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
//         author:"Jack",
//     },
// ]


// export const blogContent4 = [
//     {
//         image: require("../imagesour-work/leather/pic1.jpg"),
//         title:"Seven Doubts You Should",
//         description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
//         author:"Jack",
//     },
//     {
//         image: require("../imagesour-work/leather/pic1.jpg"),
//         title:"Seven Doubts You Should",
//         author:"Jack",
//     },
//     {
//         image: require("../imagesour-work/leather/pic2.jpg"),
//         title:"Seven Doubts You Should",
//         author:"Jack",
//     },
//     {
//         image: require("../imagesour-work/leather/pic3.jpg"),
//         title:"Seven Doubts You Should",
//         author:"Jack",
//     },
//     {
//         image: require("../imagesour-work/leather/pic2.jpg"),
//         title:"Seven Doubts You Should",
//         author:"Jack",
//     },
// ]

// export const blogContent5 = [
//     {
//         image: require("../imagesour-work/nuclear/pic1.jpg"),
//         title:"Why You Should Not Go To Industry",
//         author:"Jack",
//     },
//     {
//         image: require("../imagesour-work/nuclear/pic2.jpg"),
//         title:"Seven Doubts You Should Clarify About",
//         author:"Jack",
//     },
//     {
//         image: require("../imagesour-work/nuclear/pic3.jpg"),
//         title:"Seven Doubts You Should",
//         author:"Jack",
//     },
//     {
//         image: require("../imagesour-work/nuclear/pic4.jpg"),
//         title:"Seven Doubts You Should Clarify About",
//         author:"Jack",
//     },
// ]

// export const blogContent6 = [
//     {
//         image: require("../imagesour-work/beer/pic1.jpg"),
//         title:"Why You Should Not Go To Industry",
//         description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
//         author:"Jack",
//     },
//     {
//         image: require("../imagesour-work/beer/pic2.jpg"),
//         title:"Seven Doubts You Should Clarify About",
//         description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
//         author:"Jack",
//     },
//     {
//         image: require("../imagesour-work/beer/pic3.jpg"),
//         title:"Seven Doubts You Should",
//         description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
//         author:"Jack",
//     },
//     {
//         image: require("../imagesour-work/beer/pic2.jpg"),
//         title:"Seven Doubts You Should Clarify About",
//         description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
//         author:"Jack",
//     },
// ]

// export const blogContent7 = [
//     {
//         image: require("../imagesour-work/car/pic1.jpg"),
//         title:"Seven Doubts You Should",
//         description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
//         author:"Jack",
//     },
//     {
//         image: require("../imagesour-work/car/pic2.jpg"),
//         title:"Seven Doubts You Should",
//         description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
//         author:"Jack",
//     },
//     {
//         image: require("../imagesour-work/car/pic3.jpg"),
//         title:"Seven Doubts You Should",
//         description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
//         author:"Jack",
//     },
//     {
//         image: require("../imagesour-work/car/pic1.jpg"),
//         title:"Seven Doubts You Should",
//         description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
//         author:"Jack",
//     },
//     {
//         image: require("../imagesour-work/car/pic2.jpg"),
//         title:"Seven Doubts You Should",
//         description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
//         author:"Jack",
//     },
//     {
//         image: require("../imagesour-work/car/pic3.jpg"),
//         title:"Seven Doubts You Should",
//         description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
//         author:"Jack",
//     },
// ]

// export const blogContent8 = [
//     {
//         image: require("../imagesour-work/mining/pic1.jpg"),
//         title:"Seven Doubts You Should",
//         description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
//         author:"Jack",
//     },
//     {
//         image: require("../imagesour-work/mining/pic2.jpg"),
//         title:"Seven Doubts You Should",
//         description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
//         author:"Jack",
//     },
//     {
//         image: require("../imagesour-work/mining/pic3.jpg"),
//         title:"Seven Doubts You Should",
//         description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
//         author:"Jack",
//     },
//     {
//         image: require("../imagesour-work/mining/pic1.jpg"),
//         title:"Seven Doubts You Should",
//         description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
//         author:"Jack",
//     },
//     {
//         image: require("../imagesour-work/mining/pic2.jpg"),
//         title:"Seven Doubts You Should",
//         description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
//         author:"Jack",
//     },
//     {
//         image: require("../imagesour-work/mining/pic3.jpg"),
//         title:"Seven Doubts You Should",
//         description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
//         author:"Jack",
//     },
// ]

// export const blogContent9 = [
//     {
//         image: require("../imagesour-work/plastic/pic1.jpg"),
//         title:"Why You Should Not Go To Industry",
//         description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
//         author:"Jack",
//     },
//     {
//         image: require("../imagesour-work/plastic/pic2.jpg"),
//         title:"Seven Doubts You Should Clarify About",
//         author:"Jack",
//     },
//     {
//         image: require("../imagesour-work/plastic/pic3.jpg"),
//         title:"Seven Doubts You Should",
//         author:"Jack",
//     },
//     {
//         image: require("../imagesour-work/plastic/pic4.jpg"),
//         title:"Seven Doubts You Should Clarify About",
//         author:"Jack",
//     },
// ]

export const ExibitionContent1 = [
    {
        image: require('./../images/duynhat/products/8_bien_2.jpg'),
    },
    {
        image: require('./../images/duynhat/products/DSC_9304.jpg'),
    },
    {
        image: require('./../images/duynhat/products/Paper/DSC_9343.jpg'),
    },
    {
        image: require('./../images/duynhat/products/Paper/DSC_9450.jpg'),
    },
]

// export const exibitionContent2 = [
//     {
//         image: require('./../imagesgallery/leather/pic1.jpg'),
//     },
//     {
//         image: require('./../imagesgallery/leather/pic2.jpg'),
//     },
//     {
//         image: require('./../imagesgallery/leather/pic3.jpg'),
//     },
//     {
//         image: require('./../imagesgallery/leather/pic4.jpg'),
//     },
// ]


export const projectContent1 = [
    {
        image: require('../images/duynhat/products/GAO_ST.jpg'),
        projectName: 'Flexible Rice Packaging',
        class: 1
    },
    {
        image: require('../images/duynhat/products/DSC_9507.jpg'),
        projectName: 'Frozen Seafood Packaging',
        class: 1
    },
    {
        image: require('../images/duynhat/products/nhan_quan_31.jpg'),
        projectName: 'Laminated Rolls',
        class: 1
    },
    {
        image: require('../images/duynhat/products/Paper/DSC_9343.jpg'),
        projectName: 'Paper Container Packaging',
        class: 1
    },
    {
        image: require('../images/duynhat/products/IMG_0085.jpg'),
        projectName: 'Custom Stickers',
        class: 1
    },

]


export const projectContent2= [
    {
        image: require('../images/duynhat/products/GAO_ST.jpg'),
        projectName: 'Túi Nhựa Đựng Gạo',
        class: 2
    },
    {
        image: require('../images/duynhat/products/DSC_9507.jpg'),
        projectName: 'Túi Hải Sản Đông Lạnh',
        class: 2
    },
    {
        image: require('../images/duynhat/products/nhan_quan_31.jpg'),
        projectName: 'Nhãn Cuộn',
        class: 2
    },
    {
        image: require('../images/duynhat/products/Paper/DSC_9343.jpg'),
        projectName: 'Hộp Giấy Hải Sản',
        class: 2
    },
    {
        image: require('../images/duynhat/products/IMG_0085.jpg'),
        projectName: 'Nhãn Dán',
        class: 2
    },

]


// export const projectContent2 = [
//     {
//         image: require('../imagesour-work/solarplant/pic1.jpg'),
//         projectName: 'Manufacturing',
//     },
//     {
//         image: require('../imagesour-work/solarplant/pic2.jpg'),
//         projectName: 'Iron Making',
//     },
//     {
//         image: require('../imagesour-work/solarplant/pic3.jpg'),
//         projectName: 'Steel Pipes',
//     },
//     {
//         image: require('../imagesour-work/solarplant/pic4.jpg'),
//         projectName: 'Structural Steel',
//     },
// 	{
//         image: require('../imagesour-work/solarplant/pic3.jpg'),
//         projectName: 'Steel Pipes',
//     },
//     {
//         image: require('../imagesour-work/solarplant/pic4.jpg'),
//         projectName: 'Structural Steel',
//     },

// ]

// export const servicesContent1 = [
//     {
//         serviceName:"Wind Turbines",
//         icon:"flaticon-worker"
//     },
//     {
//         serviceName:"Solar Panels",
//         icon:"flaticon-factory"
//     },
//     {
//         serviceName:"Maintenance",
//         icon:"flaticon-settings"
//     },
//     {
//         serviceName:"Maintenance",
//         icon:"flaticon-engineer-1"
//     }
// ]

// export const serviceContent2 = [
//     {
//         serviceName:"Material Engineering",
//         icon:"flaticon-worker"
//     },
//     {
//         serviceName:"Power and Energy",
//         icon:"flaticon-factory"
//     },
//     {
//         serviceName:"Agricultural Engineering",
//         icon:"flaticon-settings"
//     },
//     {
//         serviceName:"Petroleum Engineering",
//         icon:"flaticon-engineer-1"
//     },
//     {
//         serviceName:"Material Engineering",
//         icon:"flaticon-worker"
//     },
// ]

// export const servicesContent3 = [
//     {
//         image: require("../imagesour-work/solarplant/pic1.jpg"),
//         description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
//         serviceName:"Wind Turbines",
//     },
//     {
//         image: require("../imagesour-work/solarplant/pic2.jpg"),
//         description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
//         serviceName:"Solar Panels",
//     },
//     {
//         image: require("../imagesour-work/solarplant/pic3.jpg"),
//         description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
//         serviceName:"Maintenance",
//     },
//     {
//         image: require("../imagesour-work/solarplant/pic2.jpg"),
//         description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
//         serviceName:"Solar Panels",
//     },
//     {
//         image: require("../imagesour-work/solarplant/pic3.jpg"),
//         description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
//         serviceName:"Maintenance",
//     }
// ]

// export const servicesContent4 = [
//     {
//         image: require("../imagesour-services/pic1.jpg"),
//         description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
//         serviceName:"Wind Turbines",
//     },
//     {
//         image: require("../imagesour-services/pic2.jpg"),
//         description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
//         serviceName:"Solar Panels",
//     },
//     {
//         image: require("../imagesour-services/pic3.jpg"),
//         description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
//         serviceName:"Maintenance",
//     },
//     {
//         image: require("../imagesour-services/pic1.jpg"),
//         description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
//         serviceName:"Wind Turbines",
//     },
// ]

// export const homeSliderContent2 = [
//     {
//         image: require("../imagesmain-slider/slide4.jpg"),
//         title:"GAS INDUSTRY",
//         description:"Industry is ready to help you in making unique-looking and best website in a moment."
//     },
//     {
//         image: require("../imagesmain-slider/slide5.jpg"),
//         title:"OIL INDUSTRY",
//         description:"Industry is ready to help you in making unique-looking and best website in a moment."
//     }
// ]

// export const homeSliderContent3 = [
//     {
//         image: require("../imagesmain-slider/slide21.jpg"),
//         title:"",
//     },
//     {
//         image: require("../imagesmain-slider/slide22.jpg"),
//         title:"",
//     }
// ]

export const homeSliderContent4 = [
    {
        image: require("../images/duynhat/factory/DSC_9257.jpg"),
        title:"Printing, paper, plastic, and various packaging products",
        description:"Our company specializes in printing & converting paper and soft packaging mainly for the food industry, e.g. frozen seafood, agriculture products and various consumer goods. Our packaging solutions include various kinds of paper boxes, labels, bags, as well as various kinds of laminated bags & rolls for auto packing."
    },
    {
        image: require("../images/duynhat/factory/DSC_7895.jpg"),
        title:"State of the Art Facility with Latest Packaging Technologies",
        description:"We are equipped with State-of-the Art printing machines and complete line of packaging from die-cutting to embossing, creasing, glueing, and bag-making."
    },
    {
        image: require("../images/duynhat/factory/DSC_3325.jpg"),
        title:"Quality Control and Quality Assurance",
        description:"Beside our strict quality control to provide the highest service, we have also been implimenting modern quality assurance and programs: ISO 9001:2005 & BRC for food packaging  into our production  activities to minimize risks & errors to products prior delivering to customers."
    }
]
export const homeSliderContent5 = [
    {
        image: require("../images/duynhat/factory/DSC_9257.jpg"),
        title:"Sản phẩm bao bì nhựa, bao bì giấy, tem và nhãn",
        description:"Với nhiều năm kinh nghiệm, Bao Bì Duy Nhật chuyên cung cấp các loại bao bì nhựa và bao bì giấy cho nông – lâm nghiệp, thủy – hải sản, phục vụ sản xuất kinh doanh hàng hóa ở nhiều ngành, nhiều lĩnh vực."
    },
    {
        image: require("../images/duynhat/factory/DSC_7895.jpg"),
        title:"Trang thiết bị hiện đại và công nghệ sản xuất tối tân",
        description:"Nhà máy của chúng tôi được trang bị những trang thiết bị hiện đại và tối tân nhất từ cắt khuôn, rập nổi, đến gấp túi, dán túi để phục vụ các nhu cầu sản phẩm khác nhau của khách hàng."
    },
    {
        image: require("../images/duynhat/factory/DSC_3325.jpg"),
        title:"Hệ thống quản lý và đảm bảo chất lượng",
        description:"Bên cạnh hệ thống quản lý chất lượng sản phẩm chặt chẽ, Bao Bì Duy Nhật không ngừng nâng cao trình độ công nghệ để đáp ứng các chuẩn mực chất lượng quốc tế. Công ty đang áp dụng hệ thống quản lý chất lượng ISO 9001:2005 và BRC cho thực phẩm nhầm giảm thiểu lỗi và rủi ro cho sản phẩm khi đến tay khách hàng."
    }
]

// export const homeSliderContent5 = [
//     {
//         image: require("../imagesmain-slider/slide9.jpg"),
//         title:"WE BUILD YOUR DREAM",
//         description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
//     },
//     {
//         image: require("../imagesmain-slider/slide10.jpg"),
//         title:"WE ARE CONSTRUCT",
//         description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
//     }
// ]


// export const homeSliderContent6 = [
//     {
//         image: require("../imagesmain-slider/slide11.jpg"),
//         title:"Solar Wind Power Technology ",
//         description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
//     },
//     {
//         image: require("../imagesmain-slider/slide12.jpg"),
//         title:"Solar Wind Power Technology ",
//         description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
//     }
// ]

// export const homeSliderContent7 = [
//     {
//         subTitle:"Latest Technologies in Packaging Industry",
//         image: require("../images/duynhat/factory/DSC_3325.jpg"),
//         title:"Packaging Industry",
//         description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
//     },
//     {
//         subTitle:"Latest Technologies in Packaging Industry",
//         image: require("../imagesmain-slider/slide14.jpg"),
//         title:"Our Latest Item Food Industry ",
//         description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
//     }
// ]

// export const homeSliderContent8 = [
//     {
//         subTitle:"INVESTMENT OPPORTUNITIES IN LEATHER",
//         image: require("../imagesmain-slider/slide25.jpg"),
//         title:"Welcome Leather Industry",
//     },
//     {
//         subTitle:"INVESTMENT OPPORTUNITIES IN LEATHER",
//         image: require("../imagesmain-slider/slide24.jpg"),
//         title:"Welcome Leather Industry",
//     }
// ]

// export const homeSliderContent9 = [
//     {
//         subTitle:"INVESTMENT OPPORTUNITIES IN LEATHER",
//         image: require("../imagesmain-slider/slide23.jpg"),
//         title:"Nuclear Power Plants",
//         description:"Great Lessons You Can Learn From Nuclear Plant"
//     }
// ]

// export const homeSliderContent10 = [
//     {
//         subTitle2:"Sale Car – Buy Car – Car Service",
//         image: require("../imagesmain-slider/slide29.jpg"),
//         title:"Auto Mobile Industry",
//         description:" With 19+ years’ experience in combining traditions and innovations, Arty creates settings to inspire the way people live, learn, heal, work and play."
//     }
// ]

// export const homeSliderContent11 = [
//     {
//         subTitle:"Value Creation Form Waste ",
//         image: require("../imagesmain-slider/slide19.jpg"),
//         title:"Plastic Waste Managment & Recycling",
//         description:"Powering Progress Through Plastic"
//     }
// ]
export const homeSliderContentFactory = [
    {
        image: require("../images/duynhat/factory/DSC_3325.jpg"),
        title:"",
    },
    {
        image: require("../images/duynhat/factory/DSC_7874.jpg"),
        title:"",
    },
    {
        image: require("../images/duynhat/factory/DSC_9249.jpg"),
        title:"",
    },
    {
        image: require("../images/duynhat/factory/DSC_9222.jpg"),
        title:"",
    },
    {
        image: require("../images/duynhat/factory/DSC_9234.jpg"),
        title:"",
    },
    {
        image: require("../images/duynhat/factory/DSC_9237.jpg"),
        title:"",
    },
    
]
